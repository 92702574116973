import GoogleMapsApiWrapper, { Marker, MyMapComponent } from "common/GoogleMapsApi"
import JoppysImage, { icBusinessTypes, icServices, icTeam, JoppysFirestorePublicImage } from "img/JoppysImage"
import React, { Fragment, useState } from "react"
import { Link } from "react-router-dom"
import Slider from "react-slick"

export const kBusCardClass = "bg-white p-4 p-lg-5 rounded-4 mb-3"
export const kInsideCardClass = "p-2 rounded-4 bg-light text-center fs-smaller flex-fill d-flex"

export const kServices = Object.freeze({
  deferred_payments: "deferred_payments",
  hospitalization: "hospitalization",
  emergency_room_h24: "emergency_room_h24",
  home_service: "home_service",
  phone_h24: "phone_h24",
  video_consulting: "video_consulting",
  online_pricing: "online_pricing",
})

export function BusinessLogo({ busId, busData }) {
  const logo = busData.logo

  if (logo) {
    const path = `business/${busId}/${logo}`
    return (
      <JoppysFirestorePublicImage
        src={path}
        alt={busData.title}
        className="bg-white rounded-4"
        style={{ width: "180px", height: "180px" }}
        content={icBusinessTypes[busData.type]}
      />
    )
  } else {
    return (
      <JoppysImage
        className="bg-white rounded-4"
        alt={busData.title}
        style={{ width: "180px", height: "180px" }}
        content={icBusinessTypes[busData.type]}
      />
    )
  }
}

export function PeopleProfilePic({ id, data, ...other }) {
  const avatar = data.avatar
  const style = other.style || { width: "180px", height: "180px" }
  if (avatar) {
    const path = `people/${id}/${avatar}`
    return <JoppysFirestorePublicImage src={path} alt={data.title} className="bg-light rounded-circle" style={style} />
  } else {
    return <div className="bg-white rounded-circle" style={style}></div>
  }
}

export function PhoneNumbers({ busData }) {
  const deprecatedPhone = busData.phone
  const phones = busData.phones
  if (phones) {
    return phones.map((e, index) => {
      return (
        <p key={index}>
          <i className="bi bi-telephone me-2"></i>
          {e.num ?? ""} {e.note && " - " + e.note}
        </p>
      )
    })
  } else if (deprecatedPhone) {
    return (
      <>
        <i className="bi bi-telephone me-2"></i> {deprecatedPhone.primary}
        {deprecatedPhone.secondaries &&
          deprecatedPhone.secondaries.map((element, index) => {
            return (
              <p key={index}>
                <br />
                <i className="bi bi-telephone me-2"></i>
                {element}
              </p>
            )
          })}
      </>
    )
  }
}

export function Address({ busData }) {
  const address = busData.address
  if (address) {
    return (
      <div className="d-flex flex-column mb-3">
        <h5 className="mb-3">Indirizzo</h5>
        <p>
          {address.street} {address.house_number}
          <br />
          {address.postal_code} {address.city}, {address.district}
          <br />
        </p>
        <div className="mt-3">
          <a
            href={"https://www.google.com/maps/place/" + address.geo.location.lat + "," + address.geo.location.lng}
            target="_blank"
            rel="noopener noreferrer"
          >
            Apri in Google Maps
          </a>
        </div>
      </div>
    )
  }
}

export function MapWithMarker({ busData }) {
  const location = busData.address.geo.location
  if (location) {
    return (
      <>
        <GoogleMapsApiWrapper>
          <MyMapComponent className="rounded-3" height={300} center={location} zoom={17}>
            <Marker position={location} draggable={false} />
          </MyMapComponent>
        </GoogleMapsApiWrapper>
      </>
    )
  }
}

function dateToStringFormatYYYYMMDD(date) {
  return `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, "0")}${date
    .getDate()
    .toString()
    .padStart(2, "0")}`
}

export function BusSchedule({ busData }) {
  // show 7 days
  const [num, setNum] = useState(7)

  const dateStringOption = {
    month: "short",
    day: "numeric",
  }
  const dayStringOption = {
    weekday: "long",
  }
  const timeStringOption = {
    hour: "numeric",
    minute: "numeric",
  }

  // get values from document
  // schedule is the weekdays standard schedule
  // example of schedule data. map of each day of the week (0 is sunday), array with an element for each time range, each element is a map with "start" and "end" in minutes. empty array means closed
  // {
  //   0: [],
  //   1: [
  //     { start: 9 * 60, end: 12 * 60 },
  //     { start: 15 * 60, end: 18 * 60 },
  //   ],
  //  .......
  //  .......
  //  .......
  //  .......
  //   5: [
  //     { start: 9 * 60, end: 12 * 60 },
  //     { start: 15 * 60, end: 18 * 60 },
  //   ],
  //   6: [{ start: 9 * 60, end: 12 * 60 }],
  // }
  const schedule = busData.schedule
  // schedule that overrides the standars
  // sample of override data. map of key formatted as date aaaammyy
  // (closed for Christmas, different schedule on 26/12/2025)
  // {
  //   20251225: [],
  //   20251226: [{ start: 540, end: 720 }],
  // }
  const overrideSchedule = busData.overrideSchedule

  if (schedule) {
    // current data
    const dateWithoutTime = new Date()
    dateWithoutTime.setHours(0, 0, 0, 0)

    // rows are: || weekday | short date | columns of time schedule ||
    const rows = []
    for (let i = 0; i < num; i++) {
      // init new data to not modify dateWithoutTime
      const rowDate = new Date(dateWithoutTime)
      rowDate.setDate(dateWithoutTime.getDate() + i)

      let rowClassName = ""
      let weekday = rowDate.toLocaleDateString("it-IT", dayStringOption) // weekday as string ("giovedì")
      let shortDate = rowDate.toLocaleDateString("it-IT", dateStringOption) // "10 gen"

      const rowDay = rowDate.getDay() // day of the week as number (0 is sunday)
      const concatenateDate = dateToStringFormatYYYYMMDD(rowDate) // format current data to YYYYMMDD to match overrideSchedule formatting

      // if there is a match in overrideSchedule use that schedule instead of standard
      let useOverrideSchedule = false
      if (overrideSchedule?.[concatenateDate]) {
        console.log("SCHEDULE: found overriden schedule on", shortDate)
        useOverrideSchedule = true
      }
      const todaySchedule = useOverrideSchedule ? overrideSchedule[concatenateDate] : schedule[rowDay]

      const timeRows = []

      if (todaySchedule?.length) {
        todaySchedule?.forEach((e, index) => {
          // e is time range: e.start, e.end in minutes
          // calculate and format start and end time
          rowDate.setHours(Math.floor(e.start / 60), e.start % 60, 0, 0)
          const timeStart = rowDate.toLocaleTimeString("it", timeStringOption)
          rowDate.setHours(Math.floor(e.end / 60), e.end % 60, 0, 0)
          const timeEnd = rowDate.toLocaleTimeString("it", timeStringOption)
          timeRows.push(
            <div key={index}>
              {/* bold ond current day */}
              {i === 0 ? (
                <b>
                  {timeStart} - {timeEnd}
                </b>
              ) : (
                <>
                  {timeStart} - {timeEnd}
                </>
              )}
            </div>
          )
        })
      } else {
        // no time ranges -> closed
        timeRows.push(<div key={0}>chiuso</div>)
      }

      if (i === 0) {
        // bold on current day
        rowClassName = "bg-joppys-pet"
        weekday = <b>{weekday}</b>
        shortDate = <b>{shortDate}</b>
      } else if (i % 2 === 0) {
        rowClassName = "bg-gray"
      }
      rows.push(
        <div key={i} className={"row py-1 " + rowClassName}>
          <div className="col text-truncate">{weekday}</div>
          <div className="col text-truncate">{shortDate}</div>
          <div className="col-5 text-nowrap">{timeRows}</div>
        </div>
      )
    }

    return (
      <>
        <div className={kBusCardClass}>
          <div className="d-flex flex-column mb-3">
            <h5 className="mb-3">Orari</h5>
            {rows}
          </div>
          {num <= 7 && (
            // show other 7 days
            <button type="button" className="btn btn-link p-0" onClick={() => setNum(num + 7)}>
              Mostra altro
            </button>
          )}
        </div>
      </>
    )
  }
}

export function BusOrari({ busData }) {
  const dateStringOption = {
    month: "short",
    day: "numeric",
  }
  const dayStringOption = {
    weekday: "long",
  }
  const timeStringOption = {
    hour: "numeric",
    minute: "numeric",
  }

  const schedule = {
    0: [],
    1: [
      [9 * 60, 12 * 60],
      [15 * 60, 18 * 60],
    ],

    2: [
      [9 * 60, 12 * 60],
      [15 * 60, 18 * 60],
    ],
    3: [
      [9 * 60, 12 * 60],
      [15 * 60, 18 * 60],
    ],
    4: [
      [9 * 60, 12 * 60],
      [15 * 60, 18 * 60],
    ],
    5: [
      [9 * 60, 12 * 60],
      [15 * 60, 18 * 60],
    ],
    6: [[9 * 60, 12 * 60]],
  }
  const orari = busData.address

  const dateWithoutTime = new Date()
  dateWithoutTime.setHours(0, 0, 0, 0)

  const rows = []
  for (let i = 0; i < 7; i++) {
    const rowDate = new Date(dateWithoutTime)
    rowDate.setDate(dateWithoutTime.getDate() + i)
    const weekday = rowDate.toLocaleDateString("it-IT", dayStringOption)
    const shortDate = rowDate.toLocaleDateString("it-IT", dateStringOption)

    const rowDay = rowDate.getDay()

    const timeRows = []
    if (schedule[rowDay].length) {
      schedule[rowDay]?.forEach((e) => {
        rowDate.setHours(Math.floor(e[0] / 60), e[0] % 60, 0, 0)
        const timeStart = rowDate.toLocaleTimeString("it", timeStringOption)
        rowDate.setHours(Math.floor(e[1] / 60), e[1] % 60, 0, 0)
        const timeEnd = rowDate.toLocaleTimeString("it", timeStringOption)
        timeRows.push(
          <div>
            {timeStart} - {timeEnd}
          </div>
        )
      })
    } else {
      timeRows.push(<div>chiuso</div>)
    }

    rows.push(
      <div className="row mb-2">
        <div className="col-4">{weekday}</div>
        <div className="col-auto">{shortDate}</div>
        <div className="col">{timeRows}</div>
      </div>
    )
  }

  if (orari) {
    return (
      <div className="d-flex flex-column mb-3">
        <h5 className="mb-3">Orari</h5>
        {rows}
      </div>
    )
  }
}

export function BusServices({ busData }) {
  const servicesData = {
    [kServices.deferred_payments]: { name: "Pagamenti dilazionati", image: "" },
    [kServices.hospitalization]: { name: "Degenza animali", image: "" },
    [kServices.emergency_room_h24]: { name: "Pronto soccorso aperto h24", image: "" },
    [kServices.home_service]: { name: "Servizio a domicilio", image: "" },
    [kServices.phone_h24]: { name: "Reperibilità h24", image: "" },
    [kServices.video_consulting]: { name: "Video consulenze", image: "" },
    [kServices.online_pricing]: { name: "Tariffario online", image: "" },
  }

  const activeServices = [
    "deferred_payments",
    "hospitalization",
    "emergency_room_h24",
    "home_service",
    "phone_h24",
    "video_consulting",
    "online_pricing",
  ]

  if (activeServices.length > 0) {
    return (
      <>
        <div className={kBusCardClass}>
          <div className="row gx-3 gy-3">
            {activeServices.map((e, index) => {
              if (index % 2 === 0) {
                const evenE = e
                const oddE = activeServices[index + 1]
                return (
                  <Fragment key={index}>
                    <div className="col-4 col-lg-6 d-flex">
                      <div className={kInsideCardClass}>
                        <div className="flex-fill align-self-center">
                          <JoppysImage content={icServices.deferred_payments} style={{ width: "50px" }} />
                          <div className="mt-2">{servicesData[evenE]?.name}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 col-lg-6 d-flex">
                      {oddE && (
                        <div className={kInsideCardClass}>
                          <div className="flex-fill align-self-center">
                            <JoppysImage content={icServices.deferred_payments} style={{ width: "50px" }} />
                            <div className="mt-2">{servicesData[oddE]?.name}</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Fragment>
                )
              } else {
                return <Fragment key={index}></Fragment>
              }
            })}
          </div>
        </div>
      </>
    )
  }
}

export function BusDescription({ busData }) {
  const description = busData.description

  if (description) {
    return (
      <div className={kBusCardClass}>
        <h5 className="mb-3">Descrizione</h5>
        <p className="mb-0">{description}</p>
      </div>
    )
  }
}

export function BusTeam({ teamList }) {
  // const team = [
  //   { name: "Dott. Marco Rossi nome mega lungo", role: "Direttore sanitario" },
  //   { name: "Dott.ssa Valentina Rossi", role: "Med. vet., cardiologia" },
  //   { name: "Dott. Paolo Massi", role: "Med. vet., chirurgia" },
  //   { name: "Marta Verdi", role: "Amministrazione" },
  // ]
  const team = teamList

  if (team?.length > 0) {
    return (
      <>
        <div className={kBusCardClass}>
          <h5 className="mb-3">Il team</h5>
          <div className="row">
            {team.map((e, index) => {
              const people = e.data
              const url = people.urlId ? `/@${people.urlId}` : `/people-${e.id}`
              return (
                <div key={index} className="col-md-6">
                  <div className="row align-items-center mb-3">
                    <div className="col-auto">
                      <PeopleProfilePic id={e.id} data={e.data} style={{ width: "85px", height: "85px" }} />
                    </div>
                    <div className="col">
                      <Link to={url}>{people.title}</Link>
                      <div className="fs-smaller">
                        <i>{people.qualification}</i>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </>
    )
  }
}

export function busScheduleIsVisible(schedule) {
  return !!schedule
}

export function BusGallery({ busId, busData }) {
  let gallery = busData.gallery
  gallery = gallery?.filter((e) => e.visible)

  if (gallery?.length > 0) {
    const settings = {
      className: "slider variable-width",
      dots: true,
      infinite: true,
      centerMode: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      prevArrow: <></>,
      // nextArrow: <SampleNextArrow />
    }
    return (
      <div className={kBusCardClass}>
        <h5 className="mb-3">Foto</h5>
        <div className="slider-container text-center mb-3" style={{ marginRight: "25px", marginLeft: "0px" }}>
          <Slider {...settings}>
            {gallery.map((item, index) => (
              <div key={index}>
                <div>
                  <JoppysFirestorePublicImage
                    src={`business/${busId}/gallery/${item.name}`}
                    className="me-3 rounded-4 custom-height-breakpoint"
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    )
  }
}
