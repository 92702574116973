import JoppysImage, { icHome } from 'img/JoppysImage'
import React from 'react'

export default function AppBanner() {
  return (

          <div className="bg-joppys-pet">
          <div className="container">
            <div className="row justify-content-center pt-5 mx-auto">
              <div className="col col-12 col-md-6 align-self-center text-center text-md-start">
                <div className="row">
                  <h1 className="text-white joppys-banner">semplifica</h1>
                  <h1 className="joppys-banner">la vita con il tuo animale</h1>
                </div>
                <div className="row my-4">
                  <h5>Unisciti a Joppys, scarica l'app per il tuo dispositivo</h5>
                </div>
                <div className="row justify-content-center justify-content-md-start pb-4 gx-5 gy-4">
                  <div className="col-6 col-lg-5 col-xl-4 col-xxl-3 align-self-center">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.joppys.joppys"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <JoppysImage content={icHome.PlayStoreBadge} className="w-100" />
                    </a>
                  </div>
                  <div className="col-6 col-lg-5 col-xl-4 col-xxl-3 align-self-center">
                    <a href="https://apps.apple.com/it/app/joppys/id1543777185" target="_blank" rel="noopener noreferrer">
                      <JoppysImage content={icHome.AppStoreBadges} className="w-100" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-md-6 order-md-first align-self-end">
                <JoppysImage content={icHome.Cover} className="d-block w-100 mx-auto px-0 px-lg-3 px-xl-5" />
              </div>
            </div>
          </div>
        </div>
   
  )
}
