import React from "react"

import Home from "routes/Home"

import { createBrowserRouter, Navigate, Outlet, RouterProvider, ScrollRestoration } from "react-router-dom"
import Root from "routes/Root"
import ErrorPage from "routes/ErrorPage"
import AppBusiness from "routes/business/app/AppBusiness"
import About from "routes/About"
// import BusinessHome from "routes/BusinessHome"

import PrivateAuthRoute from "components/PrivateAuthRoute"

import BusinessPublic from "routes/BusinessPublic"
import BusinessRoot from "routes/business/BusinessRoot"
import BusinessHome from "routes/business/BusinessHome"
import RecoveryPassword from "components/RecoveryPassword"

import Contacts from "routes/Contacts"
import Privacy from "routes/legal/Privacy"
import NewsletterApp from "routes/newsletter/NewsletterApp"
import TitleWrapper from "TitleWrapper"
import SignUp from "routes/business/SignUp"
import LogIn from "routes/business/LogIn"
import BusinessCard from "routes/card/BusinessCard"

import { createFlyerLog, getBusinessPublic, getPeople, getPeopleById, getStripePrices } from "scripts/public"

// import MapNew3 from "common/MapNew3"
import AddBusiness from "routes/business/app/AddBusiness"
import EditBusiness from "routes/business/app/edit/EditBusiness"
import AppRecoveryPassword from "components/AppRecoveryPassword"
import { AuthContextProvider } from "context/AuthContext"
import Header from "common/Header"
import Dashboard from "routes/business/app/Dashboard"
import Settings from "routes/business/app/settings/Settings"
import { AppBusinessContextProvider } from "context/AppBusinessContext"
import EditTeam from "routes/business/app/edit/EditTeam"
import OffcanvasForm from "routes/business/app/generic_forms/OffcanvasForm"
import Plans from "routes/business/app/plans/Plans"
import SubscriptionSuccess from "routes/business/app/plans/SubscriptionSuccess"
import SettingsGeneral from "routes/business/app/settings/SettingsGeneral"
import SettingsBilling from "routes/business/app/settings/SettingsBilling"
import FormBusOwner from "routes/business/app/settings/FormBusOwner"
import FormBilling from "routes/business/app/settings/FormBilling"
import FormBusinessInfo from "routes/business/app/edit/FormBusinessInfo"
import FormBusinessAddress from "routes/business/app/edit/FormBusinessAddress"
import FormBusinessContacts from "routes/business/app/edit/FormBusinessContacts"
import FormBusinessServices from "routes/business/app/edit/FormBusinessServices"
import FormBusinessVetMedicalServices from "routes/business/app/edit/FormBusinessVetMedicalServices"
import PublicPage from "routes/PublicPage"

//https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
const routes = [
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: (
      <>
        <ScrollRestoration />
        <TitleWrapper>
          <Outlet />
        </TitleWrapper>
      </>
    ),
    children: [
      {
        path: "",
        element: (
          <>
            <Root />
          </>
        ),
        errorElement: <ErrorPage />,
        children: [
          {
            path: "",
            element: <Home />,
          },
          // {

          //   element: <PeoplePublic />,
          //   loader: async ({ params }) => {
          //     try {
          //       return await getPeople('vet', params.vetId)
          //     } catch (e) {
          //       return false
          //     }
          //   },
          // },
          {
            path: ":publicId",
            element: <PublicPage />,
            loader: async ({ params }) => {
              try {
                const publicId = params.publicId
                let result = {}
                if (publicId.startsWith("people-")) {
                  result.type = "people"
                  result.doc = await getPeopleById(publicId.substring(7))
                } else if (publicId.substring(0, 1) === "@") {
                  result.type = "people"
                  const urlId = publicId.substring(1)

                  // controllo che non sia vuoto altrimenti prende il primo con urlId = ""
                  if (urlId) {
                    result.doc = await getPeople(urlId)
                  } else {
                    return false
                  }
                } else {
                  result.type = "business"
                  result.doc = await getBusinessPublic(publicId)
                }
                return result
              } catch (e) {
                console.log(e)
                return false
              }
            },
          },
          // {
          //   path: ":businessId",
          //   element: <BusinessPublic />,
          //   loader: async ({ params }) => {
          //     try {
          //       return await getBusinessPublic(params.businessId)
          //     } catch (e) {
          //       return false
          //     }
          //   },
          // },
          {
            path: "card",
            element: <Home />,
          },
          {
            path: "about",
            element: <About />,
          },
          {
            path: "contacts",
            element: <Contacts />,
          },
          {
            path: "legal/privacy",
            element: <Privacy />,
          },
          {
            path: "app/recovery-password",
            element: (
              <AuthContextProvider>
                <AppRecoveryPassword />
              </AuthContextProvider>
            ),
          },
          // {
          //   path: "newsletter/app",
          //   element: <NewsletterApp />,
          // },
        ],
      },
      {
        path: "business",
        element: (
          <>
            <AuthContextProvider>
              <Outlet />
            </AuthContextProvider>
          </>
        ),
        children: [
          {
            path: "",
            element: (
              <>
                <BusinessRoot>
                  <Outlet />
                </BusinessRoot>
              </>
            ),
            children: [
              {
                path: "",
                element: <BusinessHome />,
              },
              {
                path: "newsletter",
                element: <NewsletterApp />,
              },
              {
                path: "signup",
                element: <SignUp />,
              },
              {
                path: "login",
                element: <LogIn redirectToDashboadOnLogin={true} />,
              },
              {
                path: "recovery-password",
                element: <RecoveryPassword />,
              },
              {
                path: "contacts",
                element: <Contacts />,
              },
            ],
          },
          {
            path: "app",
            element: (
              <PrivateAuthRoute>
                <Outlet />
              </PrivateAuthRoute>
            ),
            children: [
              {
                path: "add_business",
                element: <AddBusiness />,
              },
              {
                path: "",
                element: (
                  <>
                    <AppBusinessContextProvider>
                      <AppBusiness />
                    </AppBusinessContextProvider>
                  </>
                ),
                children: [
                  {
                    path: "",
                    element: <Navigate to="dashboard" />,
                  },
                  {
                    path: "dashboard",
                    element: <Dashboard />,
                  },
                  {
                    path: "settings",
                    element: <Settings />,
                    children: [
                      {
                        path: "general",
                        element: <SettingsGeneral />,
                        children: [
                          {
                            path: "edit",
                            element: <FormBusOwner />,
                          },
                        ],
                      },
                      {
                        path: "billing",
                        element: <SettingsBilling />,
                        children: [
                          {
                            path: "edit",
                            element: <FormBilling />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "plans",
                    element: <Plans />,
                    loader: async () => {
                      try {
                        return await getStripePrices()
                      } catch (error) {
                        console.log(error)
                        return false
                      }
                    },
                  },
                  { path: "plans/success", element: <SubscriptionSuccess /> },
                  {
                    path: "edit_business",
                    element: <EditBusiness />,
                    children: [
                      { index: true, element: <Navigate to="info" replace /> }, // default is info path, replace is to avoid back issue
                      {
                        path: "info",
                        element: <FormBusinessInfo />,
                      },
                      {
                        path: "contacts",
                        element: <FormBusinessContacts />,
                      },
                      {
                        path: "address",
                        element: <FormBusinessAddress />,
                      },
                      {
                        path: "services",
                        element: <FormBusinessServices />,
                      },
                      {
                        path: "medical_services",
                        element: <FormBusinessVetMedicalServices />,
                      },
                      {
                        path: "team",
                        element: <EditTeam />,
                        children: [
                          {
                            path: "new",
                            element: (
                              <>
                                <OffcanvasForm title="Team">ciao</OffcanvasForm>
                              </>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        // flyers marcia delle ville
        path: "adv",
        element: <Navigate to={"/"} />,
        loader: async () => {
          try {
            console.log("writing")
            return await createFlyerLog("aprile2024")
          } catch (e) {
            console.log(e)
            return false
          }
        },
      },
      {
        path: "card/diego",
        element: <BusinessCard />,
      },
      {
        path: "inc/header-blog",
        element: <Header blog={true} />,
      },
    ],
  },
]

const router = createBrowserRouter(routes)

export default function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}
