import React from "react"
import { NavLink, Link } from "react-router-dom"
import { Navbar, Nav, Container, Button } from "react-bootstrap"
import JoppysImage, { iLogo } from "img/JoppysImage"

export default function Header(props) {
  return (
    <Navbar expand="md" className="bg-joppys-pet">
      <Container>
        <Navbar.Brand href="/">
          <JoppysImage style={{ height: "50px" }} content={iLogo} />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="ms-auto">
            <NavLink to={""} className="nav-link" target={props.blog && "_parent"}>
              Home
            </NavLink>
            <NavLink to={"/about"} className="nav-link" target={props.blog && "_parent"}>
              Chi siamo
            </NavLink>
            <NavLink to={"/contacts"} className="nav-link" target={props.blog && "_parent"}>
              Contatti
            </NavLink>
            <Link to={"/business"} target={props.blog && "_parent"}>
              <Button variant="secondary" className="mx-2">
                Joppys Business
              </Button>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
