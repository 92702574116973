import React from "react"
import { Button, Container, Nav, Navbar } from "react-bootstrap"
import { Link, NavLink /*, useNavigate*/ } from "react-router-dom"
import JoppysImage, { iLogoBusiness } from "img/JoppysImage"

export default function BusinessHeader(props) {

  return (
    <>
      <Navbar expand="md" className="bg-joppys-business">
        <Container>
          <Navbar.Brand>
            <Link to="">
              <JoppysImage style={{ height: "50px" }} content={iLogoBusiness} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="ms-auto">
              <NavLink to={"/business/contacts"} className="nav-link">
                Contatti
              </NavLink>
              <Link to="/">
                <Button className="mx-2">Torna a Joppys</Button>
              </Link>

              <NavLink to={"/business/app"} className="nav-link">
                <i className="bi bi-person me-2"></i>
                Area professionisti
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
