import JoppysImage from "img/JoppysImage"
import React from "react"

export function SquareElement({ imageContent, title, children }) {
  return (
    <div className="col col-12 col-sm-6 pb-4">
      <JoppysImage content={imageContent} className="col-3 mb-4" />
      <h4 className="joppys-h">{title}</h4>
      {children}
    </div>
  )
}

export function TwoColumnElement({ title, description, leftBody, children }) {
  return (
    <div className="row py-5 gx-5">
      <div className="col col-12 col-lg-5">
        {title && <h2 className="joppys-h mb-4">{title}</h2>}
        {description && <p className="text-larger">{description}</p>}
        {leftBody}
      </div>
      <div className="col col-lg-7">
        <div className="row">{children}</div>
      </div>
    </div>
  )
}
