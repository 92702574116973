/** @format */

import React from "react"
import { useAppBusinessContext } from "context/AppBusinessContext"
import { InputGroupBox, InputSwitchCheck } from "routes/business/app/generic_forms/GenericFormComponents"
import GenericForm from "routes/business/app/generic_forms/GenericForm"
import {
  buildGenericError,
  buildGenericFormErrors,
  popGenericFormArray,
  pushGenericFormArray,
  setGenericFormArrayField,
  setGenericFormField,
} from "routes/business/app/generic_forms/GenericFormUtils"
import {
  businessFieldNames,
  businessInitialFields,
  BusinessTypes,
  initBusinessForm,
} from "routes/business/app/BusinessForm"
import { updateBusinessDraft } from "scripts/private"
import { Button, Col, Form, Row } from "react-bootstrap"

const formName = "contacts"
const fields = {
  num: {
    init: "",
    label: "Numero di telefono",
    type: "tel",
    validate: true,
    error: " ",
    support: <i className="bi bi-telephone"></i>,
  },
  note: {
    init: "",
    label: "Nota",
  },
  main: {
    init: true,
    type: "radio",
    label: "Numero principale",
  },
  h24: {
    init: false,
    type: "switch",
    label: "Reperibilità su numero",
  },
  [businessFieldNames.email]: businessInitialFields[businessFieldNames.email],
  [businessFieldNames.more_reach]: businessInitialFields[businessFieldNames.more_reach],
  [businessFieldNames.more_appointment_only]: businessInitialFields[businessFieldNames.more_appointment_only],
}

// extra fields

const initialPhoneArrayElement = { num: "", note: "", main: false, h24: false }

function formBuilder(form, setForm, errors, disabled) {
  let reachContent
  let appointmentContent
  // let selfServiceContent

  const busType = form["NO_DB.busType"]

  if (busType === BusinessTypes.VET) {
    reachContent = (
      <>
        <Row className="my-3 text-larger">
          <Col>L’attività svolge servizio di emergenza?</Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              {[1, 2, 0].map((id) => (
                <Form.Check
                  disabled={disabled}
                  key={id}
                  type="radio"
                  name="more.reach"
                  id={`reach_${id}`}
                  label={fields["more.reach"].label[id]}
                  value={id}
                  onChange={(e) => {
                    const newValue = parseInt(e.currentTarget.value)

                    if (!newValue) {
                      // if reach = 0
                      // set h24 to false to all phones
                      const newArray = JSON.parse(JSON.stringify(form.phones)) // need deep copy
                      newArray.forEach((element) => {
                        element.h24 = false
                      })
                      setGenericFormField("phones", newArray, setForm)
                    }

                    setGenericFormField(e.currentTarget.name, newValue, setForm)
                  }}
                  checked={form["more.reach"] === id}
                  isInvalid={!!errors["more.reach"]}
                  className={!!errors["more.reach"] && "is-invalid"}
                />
              ))}
              <Form.Control.Feedback className="text-center" type="invalid">
                {errors["more.reach"]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <hr className="my-4 border-primary" />
      </>
    )

    appointmentContent = (
      <>
        <Row className="my-3 text-larger">
          <Col>Appuntamenti</Col>
        </Row>
        <Row className="my-3">
          <Col>
            <InputSwitchCheck
              checked={form["more.appointment_only"]}
              disabled={disabled}
              fields={fields}
              id="more.appointment_only"
              onChange={(e) => setGenericFormField(e.target.id, e.target.checked, setForm)}
            />
          </Col>
        </Row>
        <hr className="my-4 border-primary" />
      </>
    )
  }

  return (
    <>
      {reachContent}
      {appointmentContent}
      <Row className="my-3 text-larger">
        <Col>Contatti</Col>
      </Row>
      <Row className="mt-3">
        <Col xs="auto">
          <InputGroupBox
            fields={fields}
            disabled={disabled}
            value={form["email"]}
            // id={numFieldName + "__" + index}
            id={businessFieldNames.email}
            // index={index}
            errors={errors}
            // fields={fields}
            onChange={(e) => setGenericFormField(e.target.id, e.target.value, setForm)}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col xs="auto">
          {form.phones.map((e, index) => {
            const numContent = (
              <InputGroupBox
                fields={fields}
                disabled={disabled}
                value={e["num"]}
                id="num"
                index={index}
                errors={errors}
                onChange={(e) => setGenericFormArrayField("phones", e.target.name, index, e.target.value, setForm)}
              />
            )
            let h24Switch
            if (busType === BusinessTypes.VET && form["more.reach"]) {
              h24Switch = (
                <>
                  <label className="form-label">&nbsp;</label>
                  {/* dummy label */}
                  <InputSwitchCheck
                    checked={e["h24"]}
                    disabled={disabled}
                    fields={fields}
                    id="h24"
                    index={index}
                    onChange={(e) => setGenericFormArrayField("phones", "h24", index, e.target.checked, setForm)}
                    className="mt-2 text-start"
                  />
                  <div className="small ps-0 text-muted text-start" style={{ maxWidth: "200px" }}>
                    Attiva se questo numero è utilizzabile per la reperibilità
                  </div>
                </>
              )
            }

            const noteContent = (
              <>
                <InputGroupBox
                  fields={fields}
                  disabled={disabled}
                  value={e["note"]}
                  id="note"
                  index={index}
                  errors={errors}
                  // fields={fields}
                  onChange={(e) => setGenericFormArrayField("phones", "note", index, e.target.value, setForm)}
                />
              </>
            )

            const mainRadio = (
              <>
                <label className="form-label">&nbsp;</label>
                {/* dummy label */}
                <InputSwitchCheck
                  checked={e["main"]}
                  fields={fields}
                  id={"main"}
                  disabled={disabled}
                  className="mt-2"
                  index={index}
                  onChange={(e) => {
                    // set false to all other main fields in array
                    const newArray = JSON.parse(JSON.stringify(form.phones)) // need deep copy
                    newArray.forEach((element) => {
                      element.main = false
                    })
                    // set main to true in selected element
                    newArray[index].main = true
                    setGenericFormField("phones", newArray, setForm)
                  }}
                />
              </>
            )
            return (
              <Row key={index} className="my-3 align-items-start">
                {/* <div>
                  [{index}] num: {e.num}, h24: {e.h24}, note: {e.note}, main:{" "}
                  {e.main}
                  {"}"}
                </div> */}
                <Col xs="auto">{numContent} </Col>
                <Col xs="auto">{h24Switch}</Col>
                <Col xs="auto">{noteContent}</Col>
                <Col xs="auto">{mainRadio}</Col>
                {!e.main && (
                  <Col xs="auto">
                    <label className="form-label">&nbsp;</label>
                    {/* dummy label */}
                    <br />
                    <Button
                      hidden={disabled}
                      disabled={disabled}
                      onClick={() => popGenericFormArray("phones", index, setForm)}
                    >
                      <i className="bi bi-trash" />
                    </Button>
                  </Col>
                )}
              </Row>
            )
          })}
        </Col>
      </Row>
      {!disabled && (
        <Row className="">
          <Col xs="auto">
            <Button
              disabled={disabled}
              onClick={() => pushGenericFormArray("phones", initialPhoneArrayElement, setForm)}
            >
              Aggiungi numero <i className="ms-2 bi bi-telephone-plus" />
            </Button>
          </Col>
        </Row>
      )}

      {/* <Row>
        <Col xs="auto">
          <pre>{JSON.stringify(errors, null, 2)}</pre>
          <pre>{JSON.stringify(form, null, 2)}</pre>
        </Col>
      </Row> */}
    </>
  )
}

export default function FormBusinessContacts() {
  // get form data
  const { draftDoc } = useAppBusinessContext()
  // drafDoc is initialized in the appContext, no need to check loading data

  // initialize the form with data
  let initialForm = initBusinessForm(draftDoc.data, formName)

  //
  async function saveHandler(form) {
    console.log(form)

    if (!form["more.reach"]) {
      form.phones.forEach((e, index) => {
        e.h24 = false
      })
    }
    await updateBusinessDraft(draftDoc.id, form)
    return true
  }

  function errorBuilder(form, fields) {
    const errors = buildGenericFormErrors(form, fields)
    delete errors.num

    form.phones.forEach((e, index) => {
      const error = buildGenericError(e.num, fields.num)
      if (error) errors[`num__${index}`] = error
    })
    return errors
  }

  return (
    <GenericForm
      saveHandler={saveHandler}
      fields={fields}
      formBuilder={formBuilder}
      initialForm={initialForm}
      errorBuilder={errorBuilder}
    />
  )
}
