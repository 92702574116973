// import Maps from "common/Maps"

import JoppysImage, { icBusinessTypes } from "img/JoppysImage"

import {
  PhoneNumbers,
  Address,
  kBusCardClass,
  MapWithMarker,
  BusServices,
  BusDescription,
  BusTeam,
  BusSchedule,
  BusinessLogo,
  BusGallery,

} from "routes/business/common/commonBusinessPublicLayout"

import { useBusinessPeople } from "scripts/public"

export default function BusinessFree({ busId, busData }) {
  const teamList = useBusinessPeople(busId)

  busData.hours = { week: [{}] }

  const plan = busData.plan

  return (
    <>
      <div className={kBusCardClass}>
        <div className="row g-4">
          <div className="col-12 col-sm-auto d-flex justify-content-center ms-auto order-sm-last">
            <BusinessLogo busId={busId} busData={busData} />
          </div>
          <div className="col col-sm-auto">
            <h2 className="">{busData.title}</h2>
            <div className="mt-5">
              <h5 className="mb-3">Contatti</h5>
              <PhoneNumbers busData={busData} />
            </div>
          </div>
        </div>
      </div>
      <div className="row g-3">
        <div className={"col-12" + (plan === 1 && " col-lg-8") }>
          <div className={kBusCardClass}>
            <div className="row">
              <div className="col-md-5">
                <Address busData={busData} />
              </div>
              <div className="col-md-7">
                <MapWithMarker busData={busData} />
              </div>
            </div>
          </div>
          {plan === 1 && <BusDescription busData={busData} />}
          {plan === 1 && <BusTeam teamList={teamList} />}
          {plan === 1 && <BusGallery busId={busId} busData={busData} />}
        </div>
        {plan === 1 &&
        <div className="col-12 col-lg-4">
          {plan === 1 && <BusSchedule busData={busData} />}
          {plan === 1 && <BusServices busData={busData} />}
        </div>}
      </div>
    </>
  )
}
