// import Maps from "common/Maps"

import { JoppysFirestorePublicImage } from "img/JoppysImage"
import { kBusCardClass, PeopleProfilePic } from "routes/business/common/commonBusinessPublicLayout"

export default function Vet({ id, data }) {
  let plan = 1



  function contactItem(contact) {
    if (contact.type === 'mail')    return <><i className="bi bi-envelope" /> <a href={"mailto:" + contact.value}>{contact.value}</a><br /></>
    if (contact.type === 'facebook')    return <><i className="bi bi-facebook" /> <a href={contact.value}>{contact.value}</a> <br /></>
    if (contact.type === 'phone')    return <><i className="bi bi-telephone" /> {contact.value} <br /></>
    if (contact.type === 'instagram')    return <><i className="bi bi-instagram" /> <a href={"https://www.instagram.com/" + contact.value}>{contact.value}</a> <br /></>
  }

  return (
    <>
      <div className={kBusCardClass}>
        <div className="row g-4">
          <div className="col-12 col-lg-3 d-flex justify-content-center ms-auto order-lg-last text-center">
            <div>
              <PeopleProfilePic id={id} data={data}/>
              
              {data.contacts?.length >= 0 && (
                <div className="mt-4">
                  <h5 className="mb-3">Contatti</h5>
                  <p>
                    {data.contacts.map((e) => contactItem(e) )}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="col col-lg-9">
            <h2 className="">{data.title}</h2>
            <p className="fs-5">{data.qualification}</p>
            <p>
              <h5>Università</h5>
              {data.university}
            </p>
            <div style={{ whiteSpace: "pre-wrap" }}>
              <h5>Su di me</h5>
              {data.description.replaceAll("\\n", "\n")}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-12 col-lg-8">
          <div className={kBusCardClass}>
            <div className="row">
              <div className="col-md-5"></div>
              <div className="col-md-7"></div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4">
          <div className={kBusCardClass}></div>
        </div>
      </div> */}
    </>
  )
}
