import { SquareElement, TwoColumnElement } from "components/StaticComponents"
import { icContacts } from "img/JoppysImage"
import React, { useState } from "react"
import { Button, Form, InputGroup } from "react-bootstrap"
import { Link } from "react-router-dom"
import { submitContactForm } from "scripts/public"
import { validateEmail, validateLength } from "scripts/Validation"

export default function Contacts() {
  const [completed, setCompleted] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [form, setForm] = useState({})

  function setField(field, value) {
    setForm({
      ...form,
      [field]: value,
    })
  }

  let errors = {}
  if (submitted) {
    checkFormValid()
  }

  function checkFormValid() {
    const { email, message, object } = form
    const newErrors = {}

    if (!validateEmail(email)) newErrors.email = "Inserisci un indirizzo e-mail vaildo"
    if (!validateLength(object)) newErrors.object = "Inserisci un oggetto"
    // if (!validateLength(service)) newErrors.service = "Seleziona un servizio"
    if (!validateLength(message)) newErrors.message = "Inserisci un messaggio"

    if (Object.keys(newErrors).length === 0) {
      return true
    } else {
      errors = newErrors
      return false
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if (!checkFormValid()) {
      if (!submitted) setSubmitted(true)
      return
    }
    await submitContactForm(form)
    setCompleted(true)
  }

  return (
    <>
      <div className="bg-white">
        <div className="container">
          <TwoColumnElement
            title="Contattaci"
            description="Contattaci attraverso il form sottostante, via email o attraverso whatsapp/telegram. Il nostro team ti risponderà entro 48 ore."
          >
            <SquareElement title="Informazioni" imageContent={icContacts.ContactsInfo}>
              <div>Hai bisogno di più informazioni su Joppys? Compila il form o inviaci un’email.</div>
            </SquareElement>
            <SquareElement title="Dubbi o problemi" imageContent={icContacts.ContactsProblems}>
              <div>
                Per qualsiasi dubbio o problema non esitare a contattarci. Cercheremo di risolvere quanto prima.
              </div>
            </SquareElement>
            <SquareElement title="Idee e suggerimenti" imageContent={icContacts.ContactsSuggestions}>
              <div>
                Hai qualche consiglio su come migliorare Joppys?
                <br />
                Scrivici, siamo sempre pronti ad ascoltare
              </div>
            </SquareElement>
          </TwoColumnElement>
        </div>
      </div>
      <div className="bg-gray py-5">
        <div className="container">
          <h2 className="joppys-h mb-3">Come contattarci</h2>
          <TwoColumnElement
            leftBody={
              <>
                <div className="row gy-5 mb-5">
                  <div className="col col-12 col-sm-6 col-lg-12 text-center">
                    <div
                      className="d-flex m-auto bg-primary rounded-circle align-items-center justify-content-center"
                      style={{ width: "64px", height: "64px" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        fill="white"
                        className="bi bi-envelope-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                      </svg>
                    </div>
                    <h4 className="joppys-h my-3">Invia e-mail</h4>
                    <div className="mb-2">Invia un’email per informazioni, suggerimenti, collaborazioni:</div>
                    <a href={"mailto:info@joppys.pet"} className="text-larger">
                    info@joppys.pet
                    </a>
                  </div>
                  <div className="col col-12 col-sm-6 col-lg-12 text-center ">
                    <div
                      className="d-flex m-auto bg-primary rounded-circle align-items-center justify-content-center"
                      style={{ width: "64px", height: "64px" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        fill="white"
                        className="bi bi-chat-left-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                      </svg>
                    </div>
                    <h4 className="joppys-h my-3">Messaggia con noi</h4>
                    <div className="mb-2">
                      Più semplice un messaggio?
                      <br />
                      Puoi scriverci su:
                    </div>
                    <span className="text-larger">
                      <i className="bi bi-telegram me-2" />
                      <a href="https://t.me/joppyspet" target="_blank" rel="noopener noreferrer">
                        Telegram
                      </a>
                    </span>
                    <br />
                    <span className="text-larger">
                      <i className="bi bi-whatsapp me-2" />
                      <a href="https://wa.me/393501028336" target="_blank" rel="noopener noreferrer">
                        Whatsapp
                      </a>
                    </span>
                  </div>
                </div>
              </>
            }
          >
            {completed ? (
              <>
                <h3>Messaggio inviato!</h3>
                <div className="my-3">Grazie per averci contattato, il nostro team ti contatterà presto!</div>
                <Link to="/">Torna a Joppys</Link>
              </>
            ) : (
              <>
                <div className="mb-4 text-center text-lg-start">
                  <div
                    className="d-flex mx-auto mx-lg-0 bg-primary rounded-circle align-items-center justify-content-center"
                    style={{ width: "64px", height: "64px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      fill="white"
                      className="bi bi-file-earmark-text-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1z" />
                    </svg>
                  </div>
                  <h4 className="joppys-h my-3">Invia form</h4>
                  <div className="">
                    Compila direttamente il form sottostante con le informazioni. Ti risponderemo quanto prima.
                  </div>
                </div>
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <Form.Group className="col col-md-8 col-lg-6" controlId="email">
                      <Form.Label>Indirizzo e-mail</Form.Label>
                      <InputGroup hasValidation>
                        <InputGroup.Text>@</InputGroup.Text>
                        <Form.Control
                          type="email"
                          onChange={(e) => setField("email", e.target.value)}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div className="row mb-3">
                    <Form.Group className="col col-md-8 col-lg-6" controlId="object">
                      <Form.Label>Oggetto</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => setField("object", e.target.value)}
                        isInvalid={!!errors.object}
                      />
                      <Form.Control.Feedback type="invalid">{errors.object}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  {/* <div className="row mb-3">
                    <Form.Group className="col col-md-8 col-lg-6" controlId="service">
                      <Form.Label>Quale servizio stai utilizzando?</Form.Label>
                      <Form.Select
                        onChange={(e) => setField("service", e.target.value)}
                        isInvalid={!!errors.service}
                        defaultValue=""
                      >
                        <option value="">Scegli...</option>
                        <option value="web">Sito Web</option>
                        <option value="android">App Android</option>
                        <option value="ios">App iOS</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.service}</Form.Control.Feedback>
                    </Form.Group>
                  </div> */}
                  <div className="row mb-3">
                    <Form.Group className="col" controlId="message">
                      <Form.Label>Messaggio</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        onChange={(e) => setField("message", e.target.value)}
                        isInvalid={!!errors.message}
                      />
                      <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                      <Form.Text>
                        Inserisci i dettagli della tua richiesta, sarai ricontattato dal nostro team.
                      </Form.Text>
                    </Form.Group>
                  </div>
                  <div>
                    <div className="col">
                      <Button type="submit" size="lg">
                        Invia
                      </Button>
                    </div>
                  </div>
                </Form>
                <div className="d-none" id="d_success">
                  <div className="mb-3" id="d_response">
                    Grazie per il tuo messaggio, il nostro team ti contatterà presto!
                  </div>
                  <a href="/">Torna a Joppys</a>
                </div>
              </>
            )}
          </TwoColumnElement>
        </div>
      </div>
    </>
  )
}
