import JoppysImage, { icHome } from "img/JoppysImage"
import React from "react"
import { Button, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
// import PlayStoreBadge from "img/home/it_appstore_badge.svg"

import Accordion from "react-bootstrap/Accordion"
import AppBanner from "routes/AppBanner"

function HomeFAQ() {
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header className="rounded-5">Accordion Item #1</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Accordion Item #2</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
          eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
          mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

function MiniCard(props) {
  const obj = props.data
  return (
    <Col>
      <div className="d-grid position-relative">
        {obj.head && (
          <span className="position-absolute py-1 start-50 translate-middle-x px-4 bg-joppys-business rounded-4">
            {obj.head}
          </span>
        )}
        <div>
          <JoppysImage content={obj.img} />
        </div>
        <h5>{obj.title}</h5>
        <div className="p-2">{obj.des}</div>
      </div>
    </Col>
  )
}

export default function Home() {
  // const miniCardOwner = [
  //   {
  //     img: icHome.WhyJoppysPets,
  //     title: "Un'app per tutti",
  //     des: "Che tu abbia un cane o un drago barbuto, non importa Joppys farà al caso tuo",
  //   },
  //   {
  //     img: icHome.WhyJoppysSearch,
  //     title: "Trova servizi",
  //     des: "Cerca il servizio più adatto alle tue esigenze e alle necessità del tuo animale",
  //   },
  //   {
  //     img: icHome.WhyJoppysArchive,
  //     title: "Annota e archivia",
  //     des: "Organizza tutte le informazioni del tuo animale in un posto sicuro e sempre con te",
  //   },
  //   {
  //     img: icHome.WhyJoppysNews,
  //     title: "News",
  //     des: "Rimani aggiornato sulle ultime novità e curiosità dal mondo del pet",
  //   },
  //   {
  //     img: icHome.WhyJoppysTips,
  //     title: "Guide e consigli",
  //     des: "Ricevi guide e consigli per la gestione e cura del tuo animale domestico",
  //   },
  //   // {
  //   //   img: icHome.,
  //   //   title: "",
  //   //   des: ""
  //   // }
  // ]

  // const miniCardBusiness = [
  //   {
  //     img: icHome.WhyJoppysBusPagina,
  //     title: "Fatti conoscere",
  //     des: "Crea la tua pagina personale con tutte le informazioni relative alla tua attività",
  //   },
  //   {
  //     img: icHome.WhyJoppysBusUpdatedInfo,
  //     title: "Dati aggiornati",
  //     des: "Tieni sempre aggiornate le informazioni relative alla tua attività",
  //   },
  //   {
  //     img: icHome.WhyJoppysBusEasy,
  //     title: "Interfaccia intuitiva",
  //     des: "Crea i tuoi spazi in pochi passi con un servizio di assistenza sempre a disposizione",
  //   },
  //   {
  //     head: "Soon",
  //     img: icHome.WhyJoppysBusLoyalty,
  //     title: "Fidelizza i tuoi clienti",
  //     des: "Invia news, promozioni e contenuti rilevanti per i tuoi clienti",
  //   },
  //   {
  //     head: "Soon",
  //     img: icHome.WhyJoppysBusCalendar,
  //     title: "Gestione",
  //     des: "Gestisci i tuoi appuntamenti [...]",
  //   },
  // ]

  return (
    <>
      <AppBanner />
      <div className="bg-gray py-5">
        <div className="container">
          <div className="row mb-5 px-5">
            <div className="col col-12 col-md-6 align-self-center">
              <h2 className="joppys-h">Per tutti, tutto in un unico posto</h2>
              <p className="text-larger">Con Joppys hai tutto ciò che riguarda i tuoi animali domestici a portata di mano.</p>
            </div>
            <div className="col col-12 col-md-6 text-center">
              <JoppysImage content={icHome.PetChoice} className="my-4 col-12 col-md-10" />
            </div>
          </div>
          <div className="row text-center gy-5">
            <div className="col col-12 col-md-4 px-5 px-md-3 px-lg-5">
              <h3 className="joppys-h m-0">Servizi</h3>
              <JoppysImage content={icHome.AppScreenServices} className="my-4 col-8 col-md-9" />
              <h5 className="my-3">Cerca i servizi più adatti per te e il tuo animale</h5>
              <p>
                Trova rapidamente il professionista di cui hai bisogno. Una rete di esperti al tuo servizio, per
                garantire il meglio al tuo animale.
              </p>
            </div>
            <div className="col col-12 col-md-4 px-5 px-md-3 px-lg-5">
              <h3 className="joppys-h m-0">Agenda</h3>
              <JoppysImage content={icHome.AppScreenAgenda} className="my-4 col-8 col-md-9" />
              <h5 className="my-3">Eventi, task e abitudini per la cura del tuo animale</h5>
              <p>
                Organizza la vita del tuo animale: appuntamenti, attività e promemoria. Non dimenticare mai vaccini,
                visite o eventi importanti, grazie a notifiche personalizzate.
              </p>
            </div>
            <div className="col col-12 col-md-4 px-5 px-md-3 px-lg-5">
              <h3 className="joppys-h m-0">Salute</h3>
              <JoppysImage content={icHome.AppScreenHealth} className="my-4 col-10 col-md-9" />
              <h5 className="my-3">Archivia i dati dei tuoi animali in un unico posto</h5>
              <p>
                Tieni al sicuro e sempre a portata di mano documenti sanitari, vaccinazioni, e molto altro. La salute
                del tuo pet, sempre sotto controllo.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bg-light py-5">
        <div className="container">
          <h2 className="joppys-h text-center mb-4">Domande frequenti</h2>
          <HomeFAQ />
        </div>
      </div> */}
      {/* <div>
        <Container className="text-center py-5">
          <Row className="justify-content-center py-3 mb-5">
            <Col sm={8} lg={6}>
              <h1 className="mb-3">
                Perché <b>Joppys</b>?
              </h1>
              <div className="text-larger">
                Proprietario di animale o professionista del settore?
                <br />
                Scopri come possiamo aiutarti
              </div>
            </Col>
          </Row>
          <Card className="rounded-4 p-4 mb-5">
            <h3 className="mb-3">Per i proprietari di animali</h3>
            <Row className="row-cols-2 row-cols-md-5 g-3 justify-content-center">
              {miniCardOwner.map((e, index) => (
                <MiniCard key={index} data={e} />
              ))}
            </Row>
          </Card>
          <Card className="rounded-4 p-4">
            <h3 className="mb-3">Per i professionisti</h3>
            <Row className="row-cols-2 row-cols-md-5 g-3 justify-content-center">
              {miniCardBusiness.map((e, index) => (
                <MiniCard key={index} data={e} />
              ))}
            </Row>
          </Card>
        </Container>
      </div> */}
      {/* business */}
      <div className="bg-joppys-business">
        <div className="container text-center">
          <div className="row justify-content-center py-5 mx-auto">
            <div className="col col-md-6 align-self-center text-center text-md-start">
              <div className="h1 joppys-h mb-3">Sei un professionista del settore?</div>
              <div className="text-larger mb-5">
                Scopri i vantaggi di Joppys, come può aiutarti nel tuo lavoro e come può far crescere la tua attività
              </div>
              <Link to="/business">
                <Button size="lg" className="btn-secondary shadow">
                  Scopri di più
                </Button>
              </Link>
            </div>
            <div className="col-12 col-md-6 align-self-end pt-5 pt-md-0">
              <JoppysImage content={icHome.JoppysBusiness} className="d-block w-100 mx-auto px-0 px-lg-3 px-xl-5" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
