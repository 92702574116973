import React from "react"
import ReactDOM from "react-dom/client"

import "style.scss"
import "bootstrap-icons/font/bootstrap-icons.css"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import App from "App"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <App />
)
