import {
  addDoc,
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "scripts/FirebaseApp"
import { getCollectionSnapshot, getCollectionSnapshotFirst } from "scripts/private"

const businessPublicPath = "business"
const busPeoplePath = "people"

export async function getBusinessPeople(type, urlId) {
  const c = collection(db, busPeoplePath)
  const q = query(c, where("urlId", "==", urlId))
  const qs = await getDocs(q)
  if (qs.empty) throw Error(`Documento con urlId "${urlId}" non esiste`)
  return {
    id: qs.docs[0].id,
    data: qs.docs[0].data(),
    // updateTime: qs.docs[0].updateTime,
  }
}

export function useBusinessPeople(busId) {
  const [list, setList] = useState()

  useEffect(() => {
    const c = collection(db, busPeoplePath)
    const q = query(c, where("business", "array-contains", busId))
    const unsub = getCollectionSnapshot(q, (l) => setList(l))
    return () => unsub()
  }, [busId])

  return list
}

export async function getPeopleById(id) {
  const d = doc(db, busPeoplePath, id)
  const ds = await getDoc(d)
  if (!ds.exists) throw Error(`Documento con id "${id}" non esiste`)
  return {
    id: ds.id,
    data: ds.data(),
    // updateTime: qs.docs[0].updateTime,
  }
}

export async function getPeople(urlId) {
  const c = collection(db, busPeoplePath)
  const q = query(c, where("urlId", "==", urlId))
  const qs = await getDocs(q)
  if (qs.empty) throw Error(`Documento con urlId "${urlId}" non esiste`)
  return {
    id: qs.docs[0].id,
    data: qs.docs[0].data(),
    // updateTime: qs.docs[0].updateTime,
  }
}

export async function getBusinessPublic(urlId) {
  const c = collection(db, businessPublicPath)
  const q = query(c, where("urlId", "==", urlId))
  const qs = await getDocs(q)
  if (qs.empty) throw Error(`Documento con urlId "${urlId}" non esiste`)
  return {
    id: qs.docs[0].id,
    data: qs.docs[0].data(),
    // updateTime: qs.docs[0].updateTime,
  }
}

export async function getBusinessPublicNew(urlId) {
  const pubBusRef = collection(db, businessPublicPath)
  const q = query(pubBusRef, where("urlId", "==", urlId))
  const querySnapshot = await getDocs(q)
  return querySnapshot
}

export async function subscribeNewsletter(docData) {
  // email as document id
  docData.date = serverTimestamp()
  const newDocRef = doc(
    db,
    "public_forms",
    "newsletter",
    "app_launch",
    docData.email
  )
  return setDoc(newDocRef, docData)
}

export async function submitContactForm(docData) {
  // autogenerated id
  docData.date = serverTimestamp()
  const colRef = collection(db, "public_forms", "messages", "contacts")
  return addDoc(colRef, docData)
}

export async function createFlyerLog(tag) {
  // autogenerated id
  const docData = {
    date: serverTimestamp(),
  }
  const colRef = collection(db, "log", "flyers", tag)
  return addDoc(colRef, docData)
}

export async function getStripePrices() {
  let products = []

  // get active products
  const c = collection(db, "stripe_products")
  const q = query(c, where("active", "==", true))
  const qs = await getDocs(q)
  if (qs.empty) throw Error(`No product found`)

  for (const prodDoc of qs.docs) {
    let prices = []
    // get prices of each product
    const c = collection(prodDoc.ref, "prices")
    const qs = await getDocs(c)

    for (const priceDoc of qs.docs) {
      prices.push({ id: priceDoc.id, data: priceDoc.data() })
    }
    const prodData = prodDoc.data()
    prodData.prices = prices
    products.push({ id: prodDoc.id, data: prodData })
  }

  // Joppys subscription type is set into product.metadata.type
  // as "bus_basic", "bus_premium"
  let productsPricesByType = {
    bus_basic: {
      name: "Business Basic",
      prices: [],
    },
    bus_premium: {
      name: "Business Premium",
      prices: [1,2],
    },
  }

  productsPricesByType["bus_basic"].prices = products.filter((p) => p.data.metadata.type === "bus_basic").map(p => p.data.prices[0])
console.log(productsPricesByType["bus_basic"].prices)
  return { products, productsPricesByType }
}
