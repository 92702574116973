import JoppysImage, { icAbout, icBusinessHome } from "img/JoppysImage"
import React from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import AppBanner from "routes/AppBanner"

function teamSocialLink(contact) {
  // if (contact.type === 'mail')    return <><i className="bi bi-envelope" /> <a href={"mailto:" + contact.value}>{contact.value}</a><br /></>
  // if (contact.type === 'facebook')    return <><i className="bi bi-facebook" /> <a href={contact.value}>{contact.value}</a> <br /></>
  // if (contact.type === 'phone')    return <><i className="bi bi-telephone" /> {contact.value} <br /></>
  // if (contact.type === 'instagram')    return <><i className="bi bi-instagram" /> <a href={"https://www.instagram.com/" + contact.value}>{contact.value}</a> <br /></>
  if (contact.type === "linkedin")
    return (
      <>
        <a href={"https://www.linkedin.com/in/" + contact.value} target="_blank" rel="noopener noreferrer">
          <i className="bi bi-linkedin" />
        </a>
      </>
    )
}

const joppysTeam = [
  {
    name: "Diego Mariotti",
    description:
      "Co-founder - CEO, amante degli animali. Dall’amore per il suo bovaro nasce l’ispirazione per questa startup. Oggi guida con energia le relazioni esterne e i social.",
    image: icAbout.MariottiDiego,
    links: [{ type: "linkedin", value: "mariotti-diego" }],
  },
  {
    name: "Mirko Mariotti",
    description:
      "Co-founder - Architetto per professione, unisce estetica e funzionalità per creare un’esperienza utente fluida e intuitiva.",
    image: icAbout.MariottiMirko,
  },
  {
    name: "Alberto Puccetti",
    description:
      "Co-founder - Sviluppatore software con una profonda conoscenza dell’ecosistema Apple, garantisce soluzioni ottimali per rispondere alle esigenze di utenti e team.",
    image: icAbout.PuccettiAlberto,
  },
  {
    name: "Andrea Stefani",
    description:
      "Co-founder - Sviluppatore con una mente analitica e una cura maniacale per i dettagli, traduce idee in soluzioni tecnologiche impeccabili.",
    image: icAbout.StefaniAndrea,
  },
]
function avatarJoppysTeam(item) {
  return (
    <>
      <div className="row mb-5">
        <div className="col col-auto">
          <JoppysImage content={item.image} style={{ width: "144px" }}></JoppysImage>
        </div>
        <div className="col">
          <h4>
            <b>{item.name}</b>
          </h4>
          <div>{item.description}</div> {item.links && <div className="mt-3 fs-5">{item.links.map((e) => teamSocialLink(e))}</div>}
        </div>
      </div>
    </>
  )
}

export default function About() {
  return (
    <>
      <div className="bg-joppys-pet">
        <div className="container">
          <div className="row py-4 gx-5">
            <div className="col col-12 col-lg-7 align-self-center mt-0 mb-0">
              <h1 className="joppys-h">La nostra mission?</h1>
              <h1 className="fw-bolder">
                Semplificare la vita con il proprio animale domestico
              </h1>
              <p className="text-larger mt-4">
                Migliorare la qualità della vita degli animali domestici e dei loro proprietari, semplificando la
                gestione delle cure quotidiane e promuovendo una relazione più serena e consapevole. Attraverso un'app
                intuitiva e innovativa, mettiamo a disposizione strumenti per monitorare la salute, organizzare le
                attività e connettersi con i migliori professionisti del settore. Crediamo che la tecnologia possa
                rendere ogni momento con il tuo animale ancora più speciale e senza preoccupazioni.
              </p>
            </div>
            <div className="col col-12 col-lg-5 text-center">
              <JoppysImage content={icAbout.Cover} className="my-4 col-12 rounded-5" style={{maxWidth: "500px"}}/>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row gx-5 gy-5">
          <div className="col col-12 col-md-6">
            <h2 className="joppys-h">Il nostro team</h2>
            <p className="text-larger">
              Un mix di divertimento e determinazione, uniti dalla passione per l'innovazione e dalla nostra personale
              esperienza, cerchiamo di costruire un servizio unico in grado di facilitare la vita dei proprietari di
              animali e dei professionisti del settore.
              <br /> <br />
              Il mondo del pet ha intrapreso la trasformazione digitale e noi siamo pronti a farne parte da
              protagonisti.
            </p>
          </div>
          <div className="col col-12 col-md-6">
            {avatarJoppysTeam(joppysTeam[0])}
            {avatarJoppysTeam(joppysTeam[1])}
            {avatarJoppysTeam(joppysTeam[2])}
            {avatarJoppysTeam(joppysTeam[3])}
          </div>
        </div>
      </div>
    

      <AppBanner />
    </>
  )
}
