import React from "react"
import {  Button } from "react-bootstrap"
import JoppysImage, { icBusinessTypes, icBusinessHome } from "img/JoppysImage"
import { Link } from "react-router-dom"
import { BusinessTypes } from "routes/business/app/BusinessForm"
import { SquareElement, TwoColumnElement } from "components/StaticComponents"

export default function BusinessHome() {
  return (
    <>
      <div className="bg-joppys-business">
        <div className="container">
          <div className="row justify-content-center py-4 gx-5">
            <div className="col col-12 col-lg-8 align-self-center mt-4 mb-3">
              <h1 className="joppys-h">La trasformazione digitale del pet care è iniziata.</h1>
              <h1><b>Unisciti a noi, cresceremo insieme!</b></h1>
              <p className="text-larger">
                Crea il profilo della tua attività in pochi passi e scopri come Joppys può aiutarti nel tuo lavoro.
              </p>
              <Link to={"/business/signup"}>
                <Button size="lg" type="button" variant="secondary" className="mt-3 px-4 shadow">
                  Crea la tua pagina
                </Button>
              </Link>
              <p className="mt-3">
              Registrazione gratuita, nessuna carta di pagamento richiesta.
              </p>
              
            </div>
            <div className="col col-12 col-lg-4 text-center">
              <JoppysImage content={icBusinessHome.Cover} className="my-4 col-12 rounded-5" style={{maxWidth: "400px"}}/>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <TwoColumnElement
            title="Perchè scegliere Joppys come tuo alleato?"
            description="Scopri come possiamo aiutarti nella gestione della tua attività online."
          >
            <SquareElement title="Fatti conoscere" imageContent={icBusinessHome.WhyJoppys01}>
              <div>
                In un mondo connesso, la presenza online per un’attività è fondamentale. Nuovi clienti potranno scoprire
                la tua attività e i servizi offerti.
              </div>
            </SquareElement>
            <SquareElement title="Dati aggiornati" imageContent={icBusinessHome.WhyJoppys02}>
              <div>Gestisci e aggiorna i tuoi dati, tutto in un unico posto.</div>
            </SquareElement>
            <SquareElement title="Interfaccia intuitiva" imageContent={icBusinessHome.WhyJoppys03}>
              <div>
                Creare la tua pagina è facile e veloce. Gestisci i tuoi dati con un’interfaccia semplice e intuitiva.
              </div>
            </SquareElement>
            <SquareElement title="Fidelizza i clienti" imageContent={icBusinessHome.WhyJoppys04}>
              <div>
                Rafforza la tua presenza online con informazioni sempre aggiornate. Non solo attirerai nuovi clienti, ma
                consoliderai anche il rapporto con quelli attuali.
              </div>
            </SquareElement>
          </TwoColumnElement>
        </div>
      </div>
      <div className="bg-gray">
        <div className="container py-5">
          <h2 className="joppys-h text-center">Un'unica piattaforma per tutti i professionisti del pet</h2>
          <div className="text-larger py-5">
            <p>
              Lavoriamo con l’obiettivo di fornire una piattaforma unica che soddisfi le diverse esigenze di tutti i
              professionisti del settore.
            </p>
            <p>
              I proprietari di animali devono trovare le informazioni in modo semplice e immediato. Per questo, abbiamo
              creato schede professionali specifiche, chiare e concise che vanno subito al punto.
            </p>
            <p>
              Il nostro impegno non si ferma qui: stiamo lavorando per offrire nuove funzionalità e ampliare le
              categorie di business disponibili.
            </p>
          </div>
          <div className="col-11 col-md-8 my-4 mx-auto">
 <div className="bg-white rounded-5">
            <div className="row justify-content-center gx-3 gx-md-5 gy-4 pb-4 text-center px-2">
              <div className="col col-6 col-lg-3">
                <JoppysImage content={icBusinessTypes[BusinessTypes.VET]} className="col-8" />
                <h5 className="joppys-h mt-3">Veterinari</h5>
              </div>
              <div className="col col-6 col-lg-3">
                <JoppysImage content={icBusinessTypes[BusinessTypes.GROOMER]} className="col-8" />
                <h5 className=" joppys-h mt-3">Toelettatori</h5>
              </div>
              <div className="col col-6 col-lg-3">
                <JoppysImage content={icBusinessTypes[BusinessTypes.TRAINER]} className="col-8" />
                <h5 className="joppys-h mt-3">Educatori</h5>
              </div>
              <div className="col col-6 col-lg-3">
                <JoppysImage content={icBusinessTypes[BusinessTypes.BREEDER]} className="col-8" />
                <h5 className="joppys-h mt-3">Allevatori</h5>
              </div>
            </div>
          </div>
          </div>
         
        </div>
      </div>
      <div className="bg-joppys-business">
        <div className="container">
          <div className="row py-5">
            <div className="col col-12 col-md-7 align-self-center">
              <div className="h1 joppys-h mb-3">
                Crea la tua pagina in pochi clic e gestisci tutto da un unico pannello
              </div>
              <div className="text-larger mb-3">
                Crea gratuitamente la pagina della tua attività e migliora la tua presenza online in pochi passi. Gli
                utenti potranno facilmente trovarti, e le informazioni inserite ti aiuteranno ad attirare i clienti
                giusti.
              </div>
              <Link to={"/business/signup"}>
                <Button size="lg" type="button" className="my-3 px-4 btn-secondary shadow">
                  Crea la tua pagina
                </Button>
              </Link>
            </div>
            <div className="col col-12 col-md-5 align-self-center text-center">
              <JoppysImage content={icBusinessHome.BusinessDashbord} className="shadow my-4 col-12 rounded-5" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container">
          <h2 className="joppys-h text-center my-5">Pagina web modulare, informazioni chiare e subito raggiungibili</h2>
          <TextAndImage
            reverse
            title="Informazioni di base"
            image={<JoppysImage content={icBusinessHome.PageModule01} className="col-12" />}
            action={
              <Link to={"/business/signup"}>
                <Button size="lg" type="button" className="px-4 btn-secondary shadow">
                  Registrati
                </Button>
              </Link>
            }
          >
            Crea in pochi passi la scheda base gratuita relativa alla tua attività.
            <br />
            Rafforza la tua presenza online con dati affidabili e facilmente individuabili.
          </TextAndImage>
          <TextAndImage
            title="Orari e servizi"
            image={<JoppysImage content={icBusinessHome.PageModule02} className="col-12" />}
          >
            Le informazioni relative agli orari ed ai servizi offerti sono fondamentali per la reputazione della tua
            attività. Il posizionamento nelle ricerche sarà migliorato. I clienti sapranno quando trovarti e riceveranno
            risultati coerenti alle loro necessità.
          </TextAndImage>
          <TextAndImage
            reverse
            title="Il team e la clinica"
            image={<JoppysImage content={icBusinessHome.PageModule03} className="col-12" />}
          >
            Curare gli animali significa instaurare un rapporto diretto con le persone. Fai conoscere la storia della
            tua attività e presenta il tuo team.
          </TextAndImage>
          <TextAndImage
            title="Molto altro in arrivo"
            image={<JoppysImage content={icBusinessHome.PageModule04} className="col-12" />}
            action={
              <Link to={"/business/contacts"}>
                <Button size="lg" type="button" className="px-4 btn-secondary shadow">
                  Contattaci
                </Button>
              </Link>
            }
          >
            Stiamo sviluppando ulteriori schede personalizzabili per rendere le tue informazioni più ricche ed efficaci.
            Non dubitare a contattarci, siamo sempre pronti a ricevere nuove idee e spunti per migliorare la nostra
            piattaforma e rendere il nostro servizio più completo possibile.
          </TextAndImage>
        </div>
      </div>
    </>
  )
}

function TextAndImage(props) {
  const reverse = props.reverse
  return (
    <div className="row g-5 mb-5">
      <div className={"col col-12 col-md-7 align-self-center" + (reverse ? " order-md-2" : "")}>
        <h2 className="joppys-h m-0">{props.title}</h2>
        <div className="text-larger mt-4">{props.children}</div>
        {props.action && <div className="mt-5">{props.action}</div>}
      </div>
      {props.image && <div className="col col-12 col-md-5 align-self-center text-center">{props.image}</div>}
    </div>
  )
}


