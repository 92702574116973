import React from "react"
import { BusinessTypes } from "routes/business/app/BusinessForm"
import { kServices } from "routes/business/common/commonBusinessPublicLayout"

export default function JoppysImage(prop) {
  const { content, ...other } = prop
  if (!content?.src) {
    console.log("immagine non trovata")
  }
  return <img src={content.src} alt={content.alt} {...other} />
}

export function JoppysFirestorePublicImage({ src, alt, ...other }) {
  const publicBucketPath = "joppys-3cfd5.appspot.com/public"

  return <img src={`https://storage.googleapis.com/${publicBucketPath}/${src}`} alt={alt} {...other} />
}

const IMG_PATH = "img/"
export const iLogo = { src: require(IMG_PATH + "joppys_logo.png"), alt: "Joppys" }

// home
const HOME_PATH = IMG_PATH + "home/"
export const icHome = {
  PlayStoreBadge: { src: require(HOME_PATH + "it_playstore_badge.png"), alt: "Disponibile su Google Play" },
  AppStoreBadges: { src: require(HOME_PATH + "it_appstore_badge.svg"), alt: "Disponibile su App Store" },
  Cover: { src: require(HOME_PATH + "r01_cover.png"), alt: "La app di Joppys per il tuo smartphone" },
  PetChoice: { src: require(HOME_PATH + "r02_pet_choice.png"), alt: "" },
  AppScreenServices: { src: require(HOME_PATH + "r03_screen_01_services.png"), alt: "Servizi" },
  AppScreenAgenda: { src: require(HOME_PATH + "r03_screen_02_agenda.png"), alt: "Agenda" },
  AppScreenHealth: { src: require(HOME_PATH + "r03_screen_03_healths.png"), alt: "Salute" },
  JoppysBusiness: { src: require(HOME_PATH + "r04_joppys_business.png"), alt: "Joppys per il tuo business" },

  // BusinessHeader: { src: require(HOME_PATH + "business-header.png"), alt: "Joppys per il tuo business" },

  // WhyJoppys01: { src: require(HOME_PATH + "why-joppys-01.png"), alt: "" },
  // WhyJoppys02: { src: require(HOME_PATH + "why-joppys-02.png"), alt: "" },
  // WhyJoppys03: { src: require(HOME_PATH + "why-joppys-03.png"), alt: "" },
  // WhyJoppys04: { src: require(HOME_PATH + "why-joppys-04.png"), alt: "" },
  // PageModule01: { src: require(HOME_PATH + "business-module-01-map.png"), alt: "" },
  // PageModule02: { src: require(HOME_PATH + "business-module-02-time-schedule.png"), alt: "" },
  // PageModule03: { src: require(HOME_PATH + "business-module-03-team.png"), alt: "" },
  // PageModule04: { src: require(HOME_PATH + "business-module-04-more.png"), alt: "" },
  // BusinessDashbord: { src: require(HOME_PATH + "business-dashboard.png"), alt: "" },
  // ContactsSuggestions: { src: require(HOME_PATH + "contacts-suggestions.png"), alt: "" },
  // ContactsProblems: { src: require(HOME_PATH + "contacts-problems.png"), alt: "" },
  // ContactsInfo: { src: require(HOME_PATH + "contacts-info.png"), alt: "" },
  // WhyJoppysArchive: { src: require(HOME_PATH + "WhyJoppys_Archive.svg"), alt: "" },
  // WhyJoppysBusCalendar: { src: require(HOME_PATH + "WhyJoppys_Bus_Calendar.svg"), alt: "" },
  // WhyJoppysBusEasy: { src: require(HOME_PATH + "WhyJoppys_Bus_Easy.svg"), alt: "" },
  // WhyJoppysBusLoyalty: { src: require(HOME_PATH + "WhyJoppys_Bus_Loyalty.svg"), alt: "" },
  // WhyJoppysBusPagina: { src: require(HOME_PATH + "WhyJoppys_Bus_Pagina.svg"), alt: "" },
  // WhyJoppysBusUpdatedInfo: { src: require(HOME_PATH + "WhyJoppys_Bus_Updated_Info.svg"), alt: "" },
  // WhyJoppysNews: { src: require(HOME_PATH + "WhyJoppys_News.svg"), alt: "" },
  // WhyJoppysPets: { src: require(HOME_PATH + "WhyJoppys_Pets.svg"), alt: "" },
  // WhyJoppysSearch: { src: require(HOME_PATH + "WhyJoppys_Search.svg"), alt: "" },
  // WhyJoppysTips: { src: require(HOME_PATH + "WhyJoppys_Tips.svg"), alt: "" },
}

// contacts
const CONTACTS_PATH = IMG_PATH + "contacts/"
export const icContacts = {
  ContactsInfo: { src: require(CONTACTS_PATH + "more_info.png"), alt: "" },
  ContactsProblems: { src: require(CONTACTS_PATH + "faq.png"), alt: "" },
  ContactsSuggestions: { src: require(CONTACTS_PATH + "lightbulb.png"), alt: "" },
}

const ABOUT_PATH = IMG_PATH + "about/"
export const icAbout = {
  Team: { src: require(ABOUT_PATH + "joppys_team_web.webp"), alt: "Il team di Joppys" },
  MariottiDiego: {src: require(ABOUT_PATH + "mariotti_diego.png")},
  MariottiMirko: {src: require(ABOUT_PATH + "mariotti_mirko.png")},
  PuccettiAlberto: {src: require(ABOUT_PATH + "puccetti_alberto.png")},
  StefaniAndrea: {src: require(ABOUT_PATH + "stefani_andrea.png")},
  Cover: {src: require(ABOUT_PATH + "about_cover.jpg")},
}

const BUSINESS_PATH = IMG_PATH + "business/"

export const icBusinessHome = {
  Cover: { src: require(BUSINESS_PATH + "r01_cover_image_cropped.png"), alt: "Joppys per il tuo business" },

  WhyJoppys01: { src: require(BUSINESS_PATH + "r02_01_shopping_online.png"), alt: "" },
  WhyJoppys02: { src: require(BUSINESS_PATH + "r02_02_data.png"), alt: "" },
  WhyJoppys03: { src: require(BUSINESS_PATH + "r02_03_chat.png"), alt: "" },
  WhyJoppys04: { src: require(BUSINESS_PATH + "r02_04_discount.png"), alt: "" },

  BusinessDashbord: { src: require(BUSINESS_PATH + "r04_panel.png"), alt: "" },
  PageModule01: { src: require(BUSINESS_PATH + "r05_01_card-principale_e_mappa.png"), alt: "" },
  PageModule02: { src: require(BUSINESS_PATH + "r05_02_card_orari_e_servizi.png"), alt: "" },
  PageModule03: { src: require(BUSINESS_PATH + "r05_03_card_clinica_e_team.png"), alt: "" },
  PageModule04: { src: require(BUSINESS_PATH + "r05_04_card_altri_servizi.png"), alt: "" },
}

export const icBusinessTypes = {
  [BusinessTypes.GROOMER]: { src: require(BUSINESS_PATH + "groomer.svg"), alt: "Toelettatori" },
  [BusinessTypes.VET]: { src: require(BUSINESS_PATH + "clinic.svg"), alt: "Veterinari" },
  [BusinessTypes.BREEDER]: { src: require(BUSINESS_PATH + "breeder.svg"), alt: "Allevatori" },
  [BusinessTypes.TRAINER]: { src: require(BUSINESS_PATH + "trainer.svg"), alt: "Educotori" },
  [BusinessTypes.OTHER]: { src: require(BUSINESS_PATH + "other.svg"), alt: "Altro" },
}

export const iLogoBusiness = { src: require(BUSINESS_PATH + "joppys_logo_business.png"), alt: "Joppys" }

const BUSINESS_PAGE_PATH = BUSINESS_PATH + "page/"
export const icServices = {
  [kServices.deferred_payments]: { src: require(BUSINESS_PAGE_PATH + "deferred_payments.svg"), alt: "" },
  [kServices.hospitalization]: { src: require(BUSINESS_PAGE_PATH + "deferred_payments.svg"), alt: "" },
  [kServices.emergency_room_h24]: { src: require(BUSINESS_PAGE_PATH + "deferred_payments.svg"), alt: "" },
  [kServices.home_service]: { src: require(BUSINESS_PAGE_PATH + "deferred_payments.svg"), alt: "" },
  [kServices.phone_h24]: { src: require(BUSINESS_PAGE_PATH + "deferred_payments.svg"), alt: "" },
  [kServices.video_consulting]: { src: require(BUSINESS_PAGE_PATH + "deferred_payments.svg"), alt: "" },
  [kServices.online_pricing]: { src: require(BUSINESS_PAGE_PATH + "deferred_payments.svg"), alt: "" },
}

export const icTeam = {
  silhouette: { src: require(BUSINESS_PAGE_PATH + "circle.svg"), alt: "" },
}

const COMMON_PATH = IMG_PATH + "common/"
export const iMapPin = { src: require(COMMON_PATH + "google_maps_pin.svg"), alt: "" }
