import React from "react"
import { useLoaderData } from "react-router-dom"
import AppBanner from "routes/AppBanner"
import BusinessFree from "routes/BusinessFree"
import PeoplePublic from "routes/PeoplePublic"

export default function PublicPage() {
  const result = useLoaderData()
console.log(result)
  const doc = result?.doc
  const type = result?.type
  const docData = doc?.data
  
  document.title = (docData?.title || "Pagina non trovata") + " | Joppys"

  let content
  if (!doc) {
    content = (
      <div className="text-center py-5">
        <h2>Pagina non trovata</h2>
        <p>Prova a tornare alla pagina precedente</p>
      </div>
    )
  } else {
    if (type === 'people') {
        content = <PeoplePublic id={doc.id} data={docData} />
    }else if (type === 'business') {
        content = <BusinessFree busId={doc.id} busData={docData} />
    }
    
  }

  return <div className="bg-joppys-pet">
    <div className="container pt-4 pb-5">{content}</div>
    <AppBanner/>
    </div>
}
