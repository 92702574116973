import Footer from "common/Footer"
import Header from "common/Header"
import { useRouteError } from "react-router-dom"

export default function ErrorPage() {
  const error = useRouteError()
  console.log("errore")
console.log(error)
  document.title = "Pagina non trovata | Joppys"

  return (
    <>
     <Header />
      <div className="bg-joppys-pet">
      <div className="container py-5">
      <div className="text-center py-5">
        <h2>Pagina non trovata</h2>
        <p>Prova a tornare alla pagina precedente</p>
        
        </div>
     </div>
      </div>
      <Footer/>
    </>
  )
}
