import React from "react"
import { Container } from "react-bootstrap"

export default function Padding(props) {
  return (
    <>
      <Container fluid className="bg-gray py-5">
        {props.children}
      </Container>
    </>
  )
}
